import {
  // alluvialdiagram,
  // arcdiagram,
  barchart,
  barchartmultiset,
  barchartstacked,
  // beeswarm,
  // boxplot,
  bubblechart,
  // bumpchart,
  // circlepacking,
  circularDendrogram,
  contourPlot,
  // convexHull,
  dendrogram,
  // hexagonalBinning,
  linechart,
  // matrixplot,
  radarchart,
  // sankeydiagram,
  // streamgraph,
  sunburst,
  // treemap,
  // violinplot,
  // ganttChart,
  // voronoidiagram,
  // horizongraph,
  // parallelcoordinates,
} from '@rawgraphs/rawgraphs-charts'

// New charts, not included into first release.
// Comment at necessity.
let charts = [
  // alluvialdiagram,
  // arcdiagram,
  barchart,
  barchartmultiset,
  barchartstacked,
  // beeswarm,
  // boxplot,
  bubblechart,
  // bumpchart,
  // circlepacking,
  circularDendrogram,
  contourPlot,
  // convexHull,
  dendrogram,
  // hexagonalBinning,
  linechart,
  // matrixplot,
  radarchart,
  // sankeydiagram,
  // streamgraph,
  sunburst,
  // treemap,
  // violinplot,
  // ganttChart,
  // voronoidiagram,
  // horizongraph,
  // parallelcoordinates,
]

export default charts
